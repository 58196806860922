window.lui_references_responses = {};

$.lui("comp",".comp_references",(selector)=>{
    let map_elm = selector.find("[data-map]");
    let options = map_elm.data("map");

    $.importScript(cdnjs.gmap.replace("{apikey}",options["apikey"]),()=>{
        let coords = options["center"].split(/,\s/);
        let bounds;
        let markers_temp = [];
        let map = new google.maps.Map(map_elm[0],{
            center: new google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1])),
            zoom: 8,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
        });

        let infoWindow = new google.maps.InfoWindow({
            content: "",
            maxWidth: 168,
        });

        function getMakers(url, callback) {
            if (typeof lui_references_responses[url] === "undefined") {
                $.ajax({
                    url: url,
                    data: {ajax: +new Date},
                    dataType: "json",
                }).done(function (payload) {
                    window.lui_references_responses[url] = payload;
                    callback(payload);
                });
            }
            else {
                callback(window.lui_references_responses[url]);
            }
        }

        function generateMarker(item) {
            let coords = item["loc"].split(/,\s/);
            let title = item["title"];
            let content = item["text"];
            let point = new google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1]));
            let icon = {
                path: "M20.333,2A8.333,8.333,0,0,0,12,10.333C12,17.833,20.333,27,20.333,27s8.333-9.167,8.333-16.667A8.333,8.333,0,0,0,20.333,2Zm0,11.667a3.333,3.333,0,1,1,3.333-3.333A3.333,3.333,0,0,1,20.333,13.667Z",
                fillColor: item["color"],
                fillOpacity: 1,
                strokeWeight: 0,
                scale: 1.25,
                anchor: new google.maps.Point(16, 25)
            };
            let marker = new google.maps.Marker({
                title: title,
                map: map,
                position: point,
                icon: icon,
            });
            bounds.extend(point);
            markers_temp.push(marker);

            marker.addListener('click', function() {
                infoWindow.close();
                infoWindow.setContent(`<div class="giw-custom"><strong class="part_ui_title text--color-default">${title}</strong><div class="part_ui_wsw">${content}</div></div>`);
                infoWindow.open(map,marker);
            });
        }

        getMakers(map_elm.data("markers"),(payload)=>{
            bounds = new google.maps.LatLngBounds();
            for (let item of payload) {
                generateMarker(item);
            }
            map.fitBounds(bounds);
        });

        selector.on("click",".elm_body_head [data-markers]",function (e) {
            let el = $(this);
            if(el.is(".state--active")) return;
            e.preventDefault();
            e.stopPropagation();
            el.closest(".elm_body_head").find(".state--active").removeClass("state--active");
            el.addClass("state--active");

            for (let marker of markers_temp) {
                marker.setMap(null);
            }
            markers_temp = [];

            getMakers(el.data("markers"),(payload)=>{
                bounds = new google.maps.LatLngBounds();
                for (let item of payload) {
                    generateMarker(item);
                }
                map.fitBounds(bounds);
            });
        });

    });
});