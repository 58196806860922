$.fn.lib_expand = function () {
    let selector = $(this);
    selector.on("click","[data-lib-expand-toggle]",function (e) {
        e.preventDefault();
        let trigger = $(this),
            el = trigger.closest(selector),
            body = el.find("[data-lib-expand-target]").first(),
            options = el.data("lib-expand");

        if(el.hasClass("state--active")) {
            body.stop().slideUp(function () {
                el.removeClass("state--active");
                el.find("[data-lib-expand-toggle]").removeClass("state--active");
                if (typeof options["text"] !== "undefined") {
                    el.find("[data-lib-expand-text]").text(options["text"]["inactive"]);
                }
            });
        }
        else {
            trigger.addClass("state--active");
            if (typeof options["text"] !== "undefined") {
                el.find("[data-lib-expand-text]").text(options["text"]["active"]);
            }
            body.stop().slideDown(function () {
                let slider = body.find("[data-lib-flickity]");
                if(slider.length) {
                    if(typeof slider.data("flickity") !== "undefined") {
                        if (typeof slider.data("lib-flickity")["flex"] !== "undefined") {
                            slider.removeClass("flickity-flex");
                            slider.flickity("resize");
                            slider.addClass("flickity-flex");
                        }
                        else {
                            slider.flickity("resize");
                        }
                    }
                }
                el.addClass("state--active");
            });
        }
    });
};