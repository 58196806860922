$.lui("part",".part_professional_check",(selector)=>{
    cssLoaded(()=>{
        if (localStorage.getItem('isProfessional') === null || parseInt(localStorage.getItem('isProfessionalExpire')) < Date.now()) {
            setTimeout(() => {
                body.addClass('has--professional-modal')
                selector.addClass('is--active')
                selector.addClass('is--animate')
            }, 300)
        }
    });

    selector.on('click','[data-professional-check-approve]', function (e) {
        e.preventDefault()
        localStorage.setItem('isProfessional', "true")
        localStorage.setItem('isProfessionalExpire', (Date.now() + 28800000).toString())
        selector.removeClass('is--animate')

        setTimeout(function () {
            body.removeClass('has--professional-modal')
            selector.removeClass('is--active')
            selector.remove();
        },500)
    })

    selector.on('click','[data-professional-check-decline]', function (e) {
        e.preventDefault()
        localStorage.removeItem('isProfessional')
        localStorage.removeItem('isProfessionalExpire')

        const introTargets = selector.find('[data-professional-check-target="intro"]')
        const declineTargets = selector.find('[data-professional-check-target="decline"]')

        introTargets.each(function () {
            $(this)[0].setAttribute('aria-hidden', 'true')
        })
        declineTargets.each(function () {
            $(this)[0].removeAttribute('aria-hidden')
        })
    })
});
