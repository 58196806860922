$.lui("part",".part_nav",(selector)=>{
    cssLoaded(()=>{
        let active = selector[0].querySelectorAll(".state--active");
        selector[0].scrollLeft = active[0].offsetLeft - win.width() / 20;
    });

    if(selector.is(".is--tabs")) {
        selector.on("click",".elm_nav_item",function(e){
            let el = $(this);
            if(selector.is(":not([data-lib-tabs-nav])") && el.hasClass("state--active")) {
                e.preventDefault();
                return;
            }
            selector.find(".elm_nav_item.state--active").removeClass("state--active");
            el.addClass("state--active");
            selector.animate({
                scrollLeft: el[0].offsetLeft - win.width() / 20
            },300);
        });
    }
});