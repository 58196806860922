$.lui("part", ".part_form_search", function(selector) {
    let refs = {
      autocompleteArea: selector.find(`[data-ref="autocomplete-area"]`)
    };

    let timeout;

    selector.on("input", `[data-input="search"]`, function() {
        clearTimeout(timeout);
        selector.find(`[data-ref="autocomplete-area"]`).addClass("state--active");

        let $results_data = $("[data-results-product]");
        let query = $(this).val();
        $results_data.html("");
        $.ajax({
            method: 'post',
            dataType: 'json',
            data: {do: 'search', q: query}
        }).done(function (data) {
            $results_data.html("");
            if (typeof data.products === 'undefined') {
                $results_data.append(`                    
                    <div class="elm_group_title">
                        <strong class="part_ui_title size--big">${data.lng_not_found_products}</strong>
                    </div>
                    <div class="elm_group_inner">
                `);
            } else {
                $results_data.append(`
                    <div class="elm_autocomplete_group">
                        <div class="elm_group_title">
                            <strong class="part_ui_title size--big">${data.lng_products}</strong>
                        </div>
                        <div class="elm_group_inner">
                `);
                data.products.forEach(function (item) {
                    let image = "";

                    if(item.image && item.image.length) {
                        image = `<div class="col">
                                    <div class="part_ui_image" data-ratio="1/1">
                                        <img src="${item.image}" alt="${item.name}" />
                                    </div>
                                </div>`
                    }

                    $results_data.append(`
                        <div class="col">
                            <a href="${item.uri}" class="part_item_autocomplete row--flex" title="" data-no-swup>
                                ${image}
                                <div class="col flex--grow col--product flex--row flex--wrap">
                                    <div class="col">
                                        <strong class="part_ui_title text--weight-normal text--color-default">${item.name}</strong>
                                    </div>
                                </div>
                            </a>
                        </div> 
                    `)
                });
                if (data.total > 5) {
                    $('[data-results-product-all]').find('strong').text(data.total);
                    $results_data.append(`
                        <div class="col col--more" data-results-product-all>
                           <a href="#" class="row--flex" title="" data-no-swup>
                                <div class="col">
                                    <strong class="part_ui_more">${data.next_num_product} ${data.lng_search_products}</strong>
                                </div>
                            </a>
                        </div>
                    `);
                } else {
                    $('[data-results-product-all]').hide();
                }
                $results_data.append(`
                    </div> 
                `);
            }

            if (typeof data.articles === 'undefined') {
                $results_data.append(`
                    <br />
                    <div class="elm_group_title">
                        <strong class="part_ui_title size--big">${data.lng_not_found_articles}</strong>
                    </div>                    
                `);
            } else {
                if (data.hasOwnProperty("articles")) {
                    $results_data.append(`
                        <br />
                        <div class="elm_autocomplete_group">
                            <div class="elm_group_title">
                                <strong class="part_ui_title size--big">${data.lng_articles}</strong>
                            </div>
                            <div class="elm_group_inner">
                    `);
                    data.articles.forEach(function (item) {
                        $results_data.append(`
                            <div class="col">
                                <a href="${item.uri}" class="part_item_autocomplete row--flex" title="" data-no-swup>
                                    <div class="col">
                                        <strong class="part_ui_title text--weight-normal text--color-default">${item.name}</strong>
                                    </div>
                                </a>
                            </div>
                        `);
                    });
                    $results_data.append(`
                            </div>
                        </div>
                    `);
                }
            }
        });
    });

    doc.on("click.search-autocomplete", function (e) {
        if (selector.length && refs.autocompleteArea.hasClass("state--active")) {
            if ($(e.target).closest(selector).length === 0) {
                refs.autocompleteArea.addClass("state--hiding");
                timeout = setTimeout(function() {
                    refs.autocompleteArea.removeClass("state--active state--hiding");
                },300);
            }
        }
    });
});